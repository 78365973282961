import React, { useState } from 'react';
import { handleInsertTrack } from '../DiamondList/DiamondListFunctions';
import { trackTypeObj } from '../../constants/Common';

/* eslint jsx-a11y/anchor-is-valid: 0 */

const EnquiryDetail = (props) => {
  const [remark, setRemark] = useState('');

  return (
    <div className="diamondDetailRightPopup mt-10" style={props.style}>
      <h2 className="popupInnerTitle">Place Enquiry</h2>
      <div className="commonTextArea from-group">
        <label>Remarks</label>
        <textarea onChange={(e) => setRemark(e.target.value)}></textarea>
      </div>
      {/* <div className="popupNote">
        <span>Please Note :</span>
        <p>
          Shipping charges have been revised, For further details, <a>Click Here.</a>
        </p>
      </div> */}
      <div className="sideBarPopupButton">
        <a
          className="commonButton"
          onClick={() =>
            handleInsertTrack(
              trackTypeObj.ENQUIRY,
              props.checked,
              () => {
                props.onClose();
              },
              '',
              remark,
            )
          }
        >
          Submit
        </a>
        <a className="commonButton" onClick={() => props.onCancel()}>
          Cancel
        </a>
      </div>
    </div>
  );
};
export default EnquiryDetail;
