import React, { useState, useEffect } from 'react';
import Heading from '../../common/Heading';
import { Checkbox } from 'antd';
import { handleDownloadExcel, downloadZip, downloadFile, downloadVideo } from '../../DiamondList/DiamondListFunctions';
import OpenNotification from '../../../components/common/CommonButton/OpenNotification';
import map from 'lodash/map';
import { MEDIA_URL } from '../../../constants/Common';

const MobileDownloadOption = (props) => {
  const [visible, SetVisible] = useState(true);
  const [visible1, SetVisible1] = useState(true);
  const [visible2, SetVisible2] = useState(true);
  const [visible3, SetVisible3] = useState(true);
  const [visible4, SetVisible4] = useState(true);

  const [isExcel, setIsExcel] = useState(false);
  const [isCert, setIsCert] = useState(false);
  const [typeIIA, setTypeIIA] = useState(false);
  const [isImg, setIsImg] = useState(false);
  const [isImg2, setIsImg2] = useState(false);
  const [isFaceUp, setIsFaceUp] = useState(false);
  const [DarkField, setDarkField] = useState(false);
  const [Ideal, setIdeal] = useState(false);
  const [flourescence, setFlourescence] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [isPlt, setIsPlt] = useState(false);
  const [isHa, setIsHa] = useState(false);
  const [isAsset, setIsAsset] = useState(false);
  const [isArrow, setIsArrow] = useState(false);
  const [flag, setFlag] = useState(0);
  const [isVideo2, setIsVideo2] = useState(false);
  const [roughImage, setRoughimage] = useState(false);
  const [roughVideo, setRoughVideo] = useState(false);
  const [DImage, setDImage] = useState(false);
  const [images, setImages] = useState(false);
  const [videos, setVideos] = useState(false);
  const [certificates, setCertificates] = useState(false);
  const [roughs, setRoughs] = useState(false);

  const ShareOption = (props) => {
    return (
      <li>
        <div className="dropdownBottomItem">
          <div className="dropdownIconCheckBox">
            <Checkbox
              onChange={props.onChange}
              onClick={() => {
                if (props.checked === true) {
                  setFlag(flag - 1);
                } else if (props.checked === false) {
                  setFlag(flag + 1);
                }
              }}
              checked={props.checked}
            ></Checkbox>
          </div>
          <span
            className="shareOptionLabel"
            // onChange={(e) => {}}
          >
            {props.title}
          </span>
        </div>
      </li>
    );
  };

  const handleMenuClick = () => {
    SetVisible(!visible);
  };
  const handleMenuClick1 = () => {
    SetVisible1(!visible1);
  };
  const handleMenuClick2 = () => {
    SetVisible2(!visible2);
  };
  const handleMenuClick3 = () => {
    SetVisible3(!visible3);
  };
  const handleMenuClick4 = () => {
    SetVisible4(!visible4);
  };

  const handleDownloadClick = () => {
    if (
      isAsset ||
      isHa ||
      isCert ||
      isExcel ||
      isImg ||
      isPlt ||
      isVideo ||
      isArrow ||
      isImg2 ||
      typeIIA ||
      isFaceUp ||
      DarkField ||
      Ideal ||
      flourescence ||
      isVideo2 ||
      roughImage ||
      roughVideo ||
      DImage
    ) {
      if (props.checked.length === 1) {
        if (flag >= 2) {
          //console.log("flag is more than 2");
          //only if more than two toggles are CHECKED
          let obj = {
            filter: {
              vStnId: map(props.checked, 'vStnId'),
            },
            isExcel: isExcel,
            img: isImg,
            certFile: isCert,
            videoFile: isVideo,
            hAFile: isHa,
            arrowFile: isArrow,
            assetFile: isAsset,
            pltFile: isPlt,
            mp4Video: isVideo2,
            type2: typeIIA,
            roughVideo: roughVideo,
            darkFieldImage: DarkField,
            flsImage: flourescence,
            idealWhiteImage: Ideal,
            roughImage: roughImage,
            planImg: DImage,
            faceUp: isFaceUp,
            b2cRealImage: isImg2,
          };
          downloadZip(obj, () => {
            props.clearAll();
            props.onCloseDownloadPopup();
          });
        } else if (isExcel) {
          handleDownloadExcel({}, props.checked[0].id, (status) => {
            if (status) {
              //props.clearAll();
            }
          });
        } else if (isCert) {
          if (props.checked[0].certFile) {
            let obj = {
              path: `${MEDIA_URL}/CertiImages/${props.checked[0].rptNo}.pdf`,
              fileName: `certFile_${props.checked[0].rptNo}`,
              ext: '.pdf',
            };
            downloadFile(obj);
          } else {
            OpenNotification({
              type: 'error',
              title: 'Certificate is not available !',
            });
          }
        } else if (isVideo) {
          if (props.checked[0].videoFile) {
            let obj = {
              path: `${MEDIA_URL}/Mov/${props.checked[0].vStnId}.mp4`,
              fileName: `videoFile_${props.checked[0].vStnId}`,
              ext: '.mp4',
            };

            downloadVideo(obj);
          } else {
            OpenNotification({
              type: 'error',
              title: 'Video File is not available !',
            });
          }
        } else if (isVideo2) {
          if (props.checked[0].img) {
            let obj = {
              path: `${MEDIA_URL}/mp4_videos/${props.checked[0].vStnId}.mp4`,
              fileName: `video2File_${props.checked[0].vStnId}`,
              ext: '.mp4',
            };

            downloadVideo(obj);
          } else {
            OpenNotification({
              type: 'error',
              title: 'Video 2 File is not available !',
            });
          }
        } else if (isImg) {
          if (props.checked[0].img) {
            let obj = {
              path: `${MEDIA_URL}/RealImages/${props.checked[0].vStnId}.jpg`,
              fileName: `RealImage_${props.checked[0].vStnId}`,
              ext: '.jpg',
            };
            downloadFile(obj);
          } else {
            OpenNotification({
              type: 'error',
              title: 'Image is not available !',
            });
          }
        } else if (isArrow) {
          if (props.checked[0].arrowFile) {
            let obj = {
              path: `${MEDIA_URL}/ArrowImages/${props.checked[0].vStnId}.jpg`,
              fileName: `ArrowFile_${props.checked[0].vStnId}`,
              ext: '.jpg',
            };
            downloadFile(obj);
          } else {
            OpenNotification({
              type: 'error',
              title: 'Arrow Image is not available !',
            });
          }
        } else if (isAsset) {
          if (props.checked[0].assetFile) {
            let obj = {
              path: `${MEDIA_URL}/AssetScopeImages/${props.checked[0].vStnId}.jpg`,
              fileName: `AssetFile_${props.checked[0].vStnId}`,
              ext: '.jpg',
            };
            downloadFile(obj);
          } else {
            OpenNotification({
              type: 'error',
              title: 'Asset Image is not available !',
            });
          }
        } else if (isHa) {
          if (props.checked[0].hAFile) {
            let obj = {
              path: `${MEDIA_URL}/HeartImages/${props.checked[0].vStnId}.jpg`,
              fileName: `HeartImage_${props.checked[0].vStnId}`,
              ext: '.jpg',
            };
            downloadFile(obj);
          } else {
            OpenNotification({
              type: 'error',
              title: 'Heart & Arrow File is not available !',
            });
          }
        } else if (isPlt) {
          if (props.checked[0].pltFile) {
            let obj = {
              path: `${MEDIA_URL}/PlottingImages/${props.checked[0].vStnId}.jpg`,
              fileName: `PlottingImage_${props.checked[0].vStnId}`,
              ext: '.jpg',
            };
            downloadFile(obj);
          } else {
            OpenNotification({
              type: 'error',
              title: 'Plotting Image is not available !',
            });
          }
        } else if (isImg2) {
          if (props.checked[0].img) {
            let obj = {
              path: `${MEDIA_URL}/viewer3/RealImages/${props.checked[0].vStnId}.jpg`,
              fileName: `RealImage2_${props.checked[0].vStnId}`,
              ext: '.jpg',
            };
            downloadFile(obj);
          } else {
            OpenNotification({
              type: 'error',
              title: 'Real Image 2 is not available !',
            });
          }
        } else if (typeIIA) {
          if (props.checked[0].type2) {
            let obj = {
              path: `${MEDIA_URL}/TYPE_IIA/${props.checked[0].rptNo}.pdf`,
              fileName: `TypeIIA_${props.checked[0].rptNo}`,
              ext: '.pdf',
            };
            downloadFile(obj);
          } else {
            OpenNotification({
              type: 'error',
              title: 'TypeIIA file is not available !',
            });
          }
        } else if (DarkField) {
          if (props.checked[0].img) {
            let obj = {
              path: `${MEDIA_URL}/Dark_Field_White_BG/${props.checked[0].vStnId}.jpg`,
              fileName: `DarkField_${props.checked[0].vStnId}`,
              ext: '.jpg',
            };
            downloadFile(obj);
          } else {
            OpenNotification({
              type: 'error',
              title: 'Dark-Field Image is not available !',
            });
          }
        } else if (Ideal) {
          if (props.checked[0].img) {
            let obj = {
              path: `${MEDIA_URL}/IDEAL_White_BG/${props.checked[0].vStnId}.jpg`,
              fileName: `Ideal_${props.checked[0].vStnId}`,
              ext: '.jpg',
            };
            downloadFile(obj);
          } else {
            OpenNotification({
              type: 'error',
              title: 'Ideal Scope Image is not available !',
            });
          }
        } else if (flourescence) {
          if (props.checked[0].img) {
            let obj = {
              path: `${MEDIA_URL}/FlsImages/${props.checked[0].vStnId}.jpg`,
              fileName: `Flourescence_${props.checked[0].vStnId}`,
              ext: '.jpg',
            };
            downloadFile(obj);
          } else {
            OpenNotification({
              type: 'error',
              title: 'Flourescence Image is not available !',
            });
          }
        } else if (isFaceUp) {
          if (props.checked[0].img) {
            let obj = {
              path: `${MEDIA_URL}/Office_Light_Black_BG/${props.checked[0].vStnId}.jpg`,
              fileName: `Faceup_${props.checked[0].vStnId}`,
              ext: '.jpg',
            };
            downloadFile(obj);
          } else {
            OpenNotification({
              type: 'error',
              title: 'Face-up Image is not available !',
            });
          }
        } else if (roughImage) {
          if (props.checked[0].img) {
            let obj = {
              path: `${MEDIA_URL}/MFG/RoughImages/${props.checked[0].vStnId}.jpg`,
              fileName: `RoughImage_${props.checked[0].vStnId}`,
              ext: '.jpg',
            };
            downloadFile(obj);
          } else {
            OpenNotification({
              type: 'error',
              title: 'Rough Image is not available !',
            });
          }
        } else if (roughVideo) {
          if (props.checked[0].img) {
            let obj = {
              path: `${MEDIA_URL}/MFG/RoughVideo//${props.checked[0].vStnId}.html`,
              fileName: `RoughVideo_${props.checked[0].vStnId}`,
              ext: '.html',
            };
            downloadFile(obj);
          } else {
            OpenNotification({
              type: 'error',
              title: 'Rough Video is not available !',
            });
          }
        } else if (DImage) {
          if (props.checked[0].img) {
            let obj = {
              path: `${MEDIA_URL}/MFG/PlanImages//${props.checked[0].vStnId}.jpg`,
              fileName: `3DImage_${props.checked[0].vStnId}`,
              ext: '.jpg',
            };
            downloadFile(obj);
          } else {
            OpenNotification({
              type: 'error',
              title: '3D Image is not available !',
            });
          }
        }
      } else {
        //console.log("without flag i m here ");
        let obj = {
          filter: {
            vStnId: map(props.checked, 'vStnId'),
          },
          isExcel: isExcel,
          img: isImg,
          certFile: isCert,
          videoFile: isVideo,
          hAFile: isHa,
          arrowFile: isArrow,
          assetFile: isAsset,
          pltFile: isPlt,
          mp4Video: isVideo2,
          type2: typeIIA,
          roughVideo: roughVideo,
          darkFieldImage: DarkField,
          flsImage: flourescence,
          idealWhiteImage: Ideal,
          roughImage: roughImage,
          planImg: DImage,
          faceUp: isFaceUp,
        };
        downloadZip(obj, () => {
          props.clearAll();
          props.onCloseDownloadPopup();
        });
      }
      props.onClose();
    } else {
      OpenNotification({
        type: 'error',
        title: 'Please Select atleast 1 Option to Download.',
      });
    }
  };

  useEffect(() => {
    //console.log("effect called", images);
    if (images) {
      setFlag(2);
    }
    if (!images) {
      setFlag(0);
    }
    setIsImg(images);
    setIsImg2(images);
    setIsPlt(images);
    setIsHa(images);
    setIsAsset(images);
    setIsFaceUp(images);
    setDarkField(images);
    setIdeal(images);
    setFlourescence(images);
    setIsArrow(images);
  }, [images]);

  useEffect(() => {
    if (videos) {
      setFlag(2);
    }
    if (!videos) {
      setFlag(0);
    }
    setIsVideo(videos);
    setIsVideo2(videos);
  }, [videos]);

  useEffect(() => {
    if (certificates) {
      setFlag(2);
    }
    if (!certificates) {
      setFlag(0);
    }
    setIsCert(certificates);
    setTypeIIA(certificates);
  }, [certificates]);

  useEffect(() => {
    if (roughs) {
      setFlag(2);
    }
    if (!roughs) {
      setFlag(0);
    }
    setRoughVideo(roughs);
    setRoughimage(roughs);
    setDImage(roughs);
  }, [roughs]);

  return (
    <div>
      <Heading title="Download Stone" className="p-0" />
      <div className="">
        <div className="shareStoneMobileItem">
          <div className="shareStoneMobileHead">
            <span className="shareDropdownMobileHead d-flex">
              <div className="dropdownIconCheckBox">
                <Checkbox checked={images} onChange={() => setImages(!images)}></Checkbox>
              </div>
              Images
            </span>
            <span onClick={handleMenuClick}>
              {visible ? (
                <img src={require('../../../assets/svg/Search/down-arrow.svg')} alt="" />
              ) : (
                <img src={require('../../../assets/svg/Search/up-arrow.svg')} alt="" />
              )}
            </span>
          </div>
          {visible && (
            <div className="mobileShareOption">
              <ShareOption
                title="Real Image - 1"
                checked={isImg}
                // activeClass={isImg === true ? "active" : ""}
                onChange={() => {
                  setIsImg(!isImg);
                }}
              />
              <ShareOption
                title="Real Image - 2"
                checked={isImg2}
                onChange={() => {
                  setIsImg2(!isImg2);
                }}
              />
              <ShareOption title="Plotting" checked={isPlt} onChange={() => setIsPlt(!isPlt)} />
              <ShareOption title="Heart & Arrow" checked={isHa} onChange={() => setIsHa(!isHa)} />
              <ShareOption title="Asset Scope" checked={isAsset} onChange={() => setIsAsset(!isAsset)} />
              <ShareOption title="Arrow Image" checked={isArrow} onChange={() => setIsArrow(!isArrow)} />
              <ShareOption title="Face Up Image" checked={isFaceUp} onChange={() => setIsFaceUp(!isFaceUp)} />
              <ShareOption title="Dark - Field Image" checked={DarkField} onChange={() => setDarkField(!DarkField)} />
              <ShareOption title="Ideal Scope Image" checked={Ideal} onChange={() => setIdeal(!Ideal)} />
              <ShareOption
                title="Flouresence Image"
                checked={flourescence}
                onChange={() => setFlourescence(!flourescence)}
              />
            </div>
          )}
        </div>
        <div className="shareStoneMobileItem">
          <div className="shareStoneMobileHead">
            <span className="shareDropdownMobileHead d-flex">
              <div className="dropdownIconCheckBox">
                <Checkbox checked={videos} onChange={() => setVideos(!videos)}></Checkbox>
              </div>
              Videos
            </span>
            <span onClick={handleMenuClick1}>
              {visible1 ? (
                <img src={require('../../../assets/svg/Search/down-arrow.svg')} alt="" />
              ) : (
                <img src={require('../../../assets/svg/Search/up-arrow.svg')} alt="" />
              )}
            </span>
          </div>
          {visible1 && (
            <div className="mobileShareOption">
              <ShareOption title="Video 1" checked={isVideo} onChange={() => setIsVideo(!isVideo)} />
              <ShareOption title="Video 2" checked={isVideo2} onChange={() => setIsVideo2(!isVideo2)} />
            </div>
          )}
        </div>
        <div className="shareStoneMobileItem">
          <div className="shareStoneMobileHead">
            <span className="shareDropdownMobileHead d-flex">
              <div className="dropdownIconCheckBox">
                <Checkbox checked={certificates} onChange={() => setCertificates(!certificates)}></Checkbox>
              </div>
              Certificates
            </span>
            <span onClick={handleMenuClick2}>
              {visible2 ? (
                <img src={require('../../../assets/svg/Search/down-arrow.svg')} alt="" />
              ) : (
                <img src={require('../../../assets/svg/Search/up-arrow.svg')} alt="" />
              )}
            </span>
          </div>
          {visible2 && (
            <div className="mobileShareOption">
              <ShareOption title="Certificate" checked={isCert} onChange={() => setIsCert(!isCert)} />
              <ShareOption title="Type IIA" checked={typeIIA} onChange={() => setTypeIIA(!typeIIA)} />
            </div>
          )}
        </div>
        <div className="shareStoneMobileItem">
          <div className="shareStoneMobileHead">
            <span className="shareDropdownMobileHead d-flex">
              <div className="dropdownIconCheckBox">{/* <Checkbox></Checkbox> */}</div>
              Excel
            </span>
            <span onClick={handleMenuClick3}>
              {visible3 ? (
                <img src={require('../../../assets/svg/Search/down-arrow.svg')} alt="" />
              ) : (
                <img src={require('../../../assets/svg/Search/up-arrow.svg')} alt="" />
              )}
            </span>
          </div>
          {visible3 && (
            <div className="mobileShareOption">
              <ShareOption checked={isExcel} onChange={() => setIsExcel(!isExcel)} title="Excel" />
            </div>
          )}
        </div>
        <div className="shareStoneMobileItem">
          <div className="shareStoneMobileHead">
            <span className="shareDropdownMobileHead d-flex">
              <div className="dropdownIconCheckBox">
                <Checkbox checked={roughs} onChange={() => setRoughs(!roughs)}></Checkbox>
              </div>
              Roughs
            </span>
            <span onClick={handleMenuClick4}>
              {visible4 ? (
                <img src={require('../../../assets/svg/Search/down-arrow.svg')} alt="" />
              ) : (
                <img src={require('../../../assets/svg/Search/up-arrow.svg')} alt="" />
              )}
            </span>
          </div>
          {visible4 && (
            <div className="mobileShareOption">
              <ShareOption title="Rough Scope" checked={roughImage} onChange={() => setRoughimage(!roughImage)} />
              <ShareOption title="3D Image" checked={DImage} onChange={() => setDImage(!DImage)} />
              <ShareOption title="Rough Video" checked={roughVideo} onChange={() => setRoughVideo(!roughVideo)} />
            </div>
          )}
        </div>
      </div>
      <div className="sideBarPopupButton">
        <a onClick={() => handleDownloadClick()} className="commonButton">
          Download
        </a>
        <a onClick={() => props.onClose()} className="commonButton">
          Cancel
        </a>
      </div>
    </div>
  );
};
export default MobileDownloadOption;
