import React, { Component } from 'react';
import moment from 'moment';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import invert from 'lodash/invert';
import InputBlock from '../InputBlock';
import Heading from '../Heading';
import {
  getColumn,
  handleSaveQuote,
  handleDownloadExcel,
  isMobile,
  getPath,
  LISTINGPAGES,
  getProjectSetting,
} from '../../DiamondList/DiamondListFunctions';
import Table from '../../DiamondList/TableBack';
import { calculate } from './SelectStone';
import { newDiamondPrice } from './FinalCalculations';
import OpenNotification from '../CommonButton/OpenNotification';
import SendEmailPopup from './SendEmail';
import { LOCAL_STORAGE_VAR, PROJECT_SETTINGS_TYPE } from '../../../constants/Common';
import { connect } from 'react-redux';
import MobileOfferPopup from './MobileOfferPopup';
import IntlMessages from '../../../util/IntlMessages';
import { isNumber, isNumeric, formatFloat } from '../../../util/utils';
/* eslint jsx-a11y/anchor-is-valid: 0 */

export const QUOTE = [0.5, 1];
export const HOURS = [2, 4, 8, 10, 24, 48];
const currentType = 'QuotePopup';

class QuotePopup extends Component {
  constructor(props) {
    super(props);
    let user = JSON.parse(window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)));
    this.state = {
      columns: [],
      data: [],
      comment: this.props.editOffer ? this.props.editOffer[0].remarks : '',
      checked: [],
      company: user && user.account ? user.account.companyName : '',
      email: '',
      maxCount: props.offerDis?.maxDisPer,
      errorMsg: false,
    };
  }

  handleQuoteChange = (value, row, blur) => {
    const { maxCount } = this.state;
    const data = [...this.state.data];
    const index = findIndex(data, { stoneId: row.stoneId });

    data[index] = { ...data[index] };
    if (parseFloat(value) >= data[index].back - maxCount) {
      data[index].finalquote = Number(parseFloat(value).toFixed(2));
      data[index] = newDiamondPrice(data[index], false, 'finalquote', data[index].finalquote);
      this.setState({ errorMsg: false });
    } else {
      data[index].finalquote = Number(
        parseFloat(data[index].newDiscount ? data[index].newDiscount : data[index].back).toFixed(2),
      );
      data[index] = newDiamondPrice(
        data[index],
        false,
        'finalquote',
        data[index].newDiscount ? data[index].newDiscount : data[index].back,
      );
      this.setState({ errorMsg: true });
      OpenNotification({
        type: 'error',
        title: `You are not allow to Quote more the ${this.state.maxCount}% on stone(s). Please try to Quote again.`,
      });
    }

    const checked = data.filter((el) => find(this.state.checked, { id: el.id }));
    this.setState({ data, checked });
  };

  handleQuoteBlur = (...args) => this.handleQuoteChange(...args, true);

  handleRateChange = (value, row, blur) => {
    const { maxCount } = this.state;
    const data = [...this.state.data];
    const index = findIndex(data, { stoneId: row.stoneId });

    data[index] = { ...data[index] };
    data[index].fnCtpr = Number(parseFloat(value).toFixed(2));
    data[index] = newDiamondPrice(data[index], false, 'fnCtpr', data[index].fnCtpr);
    const maxPricePerCarat = data[index].ctPr - data[index].ctPr * (maxCount / 100);
    if (!data[index].isFcCol) {
      if (parseFloat(data[index].calcDiscount) >= data[index].back - maxCount) {
        data[index].fnCtpr = Number(parseFloat(value).toFixed(2));
        data[index] = newDiamondPrice(data[index], false, 'fnCtpr', data[index].fnCtpr);
        this.setState({ errorMsg: false });
      } else {
        data[index].fnCtpr = Number(
          parseFloat(data[index].newPricePerCarat ? data[index].newPricePerCarat : data[index].ctPr).toFixed(2),
        );
        data[index] = newDiamondPrice(
          data[index],
          false,
          'fnCtpr',
          data[index].newPricePerCarat ? data[index].newPricePerCarat : data[index].ctPr,
        );
        this.setState({ errorMsg: true });
        OpenNotification({
          type: 'error',
          title: `You are not allow to Quote more the ${this.state.maxCount}% on stone(s). Please try to Quote again.`,
        });
      }
    } else {
      if (parseFloat(value) >= maxPricePerCarat) {
        data[index].fnCtpr = Number(parseFloat(value).toFixed(2));
        data[index] = newDiamondPrice(data[index], false, 'fnCtpr', data[index].fnCtpr);
        this.setState({ errorMsg: false });
      } else {
        data[index].fnCtpr = Number(
          parseFloat(data[index].newPricePerCarat ? data[index].newPricePerCarat : data[index].ctPr).toFixed(2),
        );
        data[index] = newDiamondPrice(
          data[index],
          false,
          'fnCtpr',
          data[index].newPricePerCarat ? data[index].newPricePerCarat : data[index].ctPr,
        );
        this.setState({ errorMsg: true });
        OpenNotification({
          type: 'error',
          title: `You are not allow to Quote more the ${this.state.maxCount}% on stone(s). Please try to Quote again.`,
        });
      }
    }

    const checked = data.filter((el) => find(this.state.checked, { id: el.id }));
    this.setState({ data, checked });
  };

  handleRateBlur = (...args) => this.handleRateChange(...args, true);

  handleValueChange = (value, row, blur) => {
    const { maxCount } = this.state;
    const data = [...this.state.data];
    const index = findIndex(data, { stoneId: row.stoneId });

    data[index] = { ...data[index] };
    data[index].fnAmt = Number(parseFloat(value).toFixed(2));
    data[index] = newDiamondPrice(data[index], false, 'fnAmt', data[index].fnAmt);
    if (parseFloat(data[index].calcDiscount) >= data[index].back - maxCount) {
      data[index].fnAmt = Number(parseFloat(value).toFixed(2));
      data[index] = newDiamondPrice(data[index], false, 'fnAmt', data[index].fnAmt);
      this.setState({ errorMsg: false });
    } else {
      data[index].fnAmt = Number(
        parseFloat(data[index].newAmount ? data[index].newAmount : data[index].amt).toFixed(2),
      );
      data[index] = newDiamondPrice(
        data[index],
        false,
        'fnAmt',
        data[index].newAmount ? data[index].newAmount : data[index].amt,
      );
      this.setState({ errorMsg: true });
      OpenNotification({
        type: 'error',
        title: `You are not allow to Quote more the ${this.state.maxCount}% on stone(s). Please try to Quote again.`,
      });
    }

    const checked = data.filter((el) => find(this.state.checked, { id: el.id }));
    this.setState({ data, checked });
  };

  handleValueBlur = (...args) => this.handleValueChange(...args, true);

  componentDidMount() {
    getProjectSetting(PROJECT_SETTINGS_TYPE.OFFER_DISCOUNT, (cb) => {
      this.setState({ maxCount: cb?.maxDisPer });
    });
    let timeLine = this.props.editOffer ? this.props.editOffer[0] : null;
    let diff = timeLine
      ? moment.duration(moment(timeLine.offerValidDate).diff(moment(timeLine.updatedAt)))._data
      : null;
    if (diff && diff.days) diff.hours += diff.days * 24;
    let data = (this.props.editOffer ? this.props.editOffer : this.props.checked)
      .map((x) => ({
        ...x,
        quote: this.props.editOffer ? (x.newDiscount - (x.back || 0)) * -1 : QUOTE[1],
        hours: this.props.editOffer && HOURS.includes(diff.hours + 1) ? diff.hours + 1 : HOURS[0],
      }))
      .map((x) => {
        if (x.newDiscount) {
          const rate = (1 + x.newDiscount / 100) * x.rap;
          return {
            ...x,
            finalquote: parseFloat(x.newDiscount).toFixed(2),
            fnCtpr: (1 + x.newDiscount / 100) * x.rap,
            fnAmt: (rate * x.crt).toFixed(2),
          };
        } else {
          const quote = parseFloat((x.quote || 0) * -1 + (x.back || 0)).toFixed(2);
          // const rate = ((x.rap * parseInt(quote)) / 100 + x.rap).toFixed(2);
          const rate = (1 + x.back / 100) * x.rap;
          return {
            ...x,
            finalquote: parseFloat((x.quote || 0) * -1 + (x.back || 0)).toFixed(2),
            fnCtpr: rate,
            fnAmt: (rate * x.crt).toFixed(2),
          };
        }
      })
      .map((x) => newDiamondPrice(x, true))
      .map((x) => newDiamondPrice(x, 'bid'))
      .map((x) => ({
        ...x,
        calcDiscount: x.newPricePerCarat
          ? parseFloat(x.calcDiscount).toFixed(2)
          : x.ctPr < x.rap
          ? -1 * Math.abs(parseFloat(x.calcDiscount).toFixed(2))
          : Math.abs(parseFloat(x.calcDiscount).toFixed(2)),
      }));
    let Columns = getColumn();

    let columns = [
      find(Columns, { id: 'srNo' }),
      find(Columns, { id: 'rap' }),
      find(Columns, { id: 'back' }),
      find(Columns, { id: 'ctPr' }),
      find(Columns, { id: 'amt' }),
      {
        Header: 'Final Quote',
        accessor: 'finalquote',
        id: 'finalquote',
        Cell: ({ row, cell }) => {
          const originalValue = React.useMemo(() => {
            return Number(parseFloat(cell.value).toFixed(2));
          }, [cell.value]);

          const [value, setValue] = React.useState(
            isNumber(row.original.calcDiscount)
              ? parseFloat(row.original.calcDiscount).toFixed(2)
              : isNumber(originalValue)
              ? parseFloat(originalValue).toFixed(2)
              : '0.00',
          );

          React.useEffect(() => {
            setValue(parseFloat(row.original.calcDiscount).toFixed(2));
          }, [row.original]);

          const handleChange = React.useCallback((e) => {
            e.preventDefault();
            const _value = e.target.value;
            if (isNumeric(_value)) setValue(_value);
          }, []);

          const handleBlur = React.useCallback((e) => {
            e.preventDefault();
            const _value = e.target.value;
            isNumeric(_value, true)
              ? this.handleQuoteBlur(_value, row.original)
              : this.handleQuoteBlur(0, row.original);
          }, []);

          const sign = Number(value) > 0 ? '+' : '';
          if (row.original.isFcCol) {
            return '-';
          }
          return (
            <div className="tableInput">
              <span className="add-sign">{sign}</span>
              <input
                style={{ paddingLeft: Number(value) > 0 ? 8 : 2 }}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          );
        },
      },
      {
        Header: 'Final Rate',
        accessor: 'fnCtpr',
        id: 'fnCtpr',
        Cell: ({ row, cell }) => {
          const originalValue = React.useMemo(() => {
            return Number(parseFloat(cell.value).toFixed(2));
          }, [cell.value]);

          const [value, setValue] = React.useState(
            isNumber(row.original.calcPricePerCarat)
              ? parseFloat(row.original.calcPricePerCarat).toFixed(2)
              : isNumber(originalValue)
              ? parseFloat(originalValue).toFixed(2)
              : '0.00',
          );

          React.useEffect(() => {
            setValue(parseFloat(row.original.calcPricePerCarat).toFixed(2));
          }, [row.original]);

          const handleChange = React.useCallback((e) => {
            e.preventDefault();
            const _value = e.target.value;
            if (isNumeric(_value)) setValue(_value);
          }, []);

          const handleBlur = React.useCallback((e) => {
            e.preventDefault();
            const _value = e.target.value;
            isNumeric(_value, true) ? this.handleRateBlur(_value, row.original) : this.handleRateBlur(0, row.original);
          }, []);
          return (
            <div className="tableInput">
              <input value={value} onChange={handleChange} onBlur={handleBlur} />
            </div>
          );
        },
      },
      {
        Header: 'Final Value',
        accessor: 'fnAmt',
        id: 'fnAmt',
        Cell: ({ row, cell }) => {
          const originalValue = React.useMemo(() => {
            return Number(parseFloat(cell.value).toFixed(2));
          }, [cell.value]);

          const [value, setValue] = React.useState(
            isNumber(row.original.calcAmount)
              ? parseFloat(row.original.calcAmount).toFixed(2)
              : isNumber(originalValue)
              ? parseFloat(originalValue).toFixed(2)
              : '0.00',
          );

          React.useEffect(() => {
            setValue(parseFloat(row.original.calcAmount).toFixed(2));
          }, [row.original]);

          const handleChange = React.useCallback((e) => {
            e.preventDefault();
            const _value = e.target.value;
            if (isNumeric(_value)) setValue(_value);
          }, []);

          const handleBlur = React.useCallback((e) => {
            e.preventDefault();
            const _value = e.target.value;
            isNumeric(_value, true)
              ? this.handleValueBlur(_value, row.original)
              : this.handleValueBlur(0, row.original);
          }, []);

          return (
            <div className="tableInput">
              <input value={value} onChange={handleChange} onBlur={handleBlur} />
            </div>
          );
        },
        // Cell: ({ row }) => <>{parseFloat(row.original.calcAmount).toFixed(2)}</>,
      },
    ].filter((el) => el && el);
    columns = [...columns, ...Columns.filter((el) => !find(columns, { id: el.id }))];
    this.setState({ columns, data, oldData: data, checked: data });
  }

  onHourChange = (e, row) => {
    let line = cloneDeep(this.state.data);
    let index = findIndex(line, { stoneId: row.stoneId });
    line[index].hours = e;
    this.setState({ data: line }, () => {
      let checked = this.state.data.filter((el) => find(this.state.checked, { id: el.id }));
      this.setState({ checked });
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.checkedData.length !== this.state.checked.length) {
      this.setState({
        checked: this.state.data.filter((x) => (find(nextProps.checkedData, { id: x.id }) ? true : false)),
      });
    }
  }

  offerHeading = () => {
    if (isMobile() && !this.state.checked.length) return null;
    let sum = calculate(this.state.checked, null, true);
    let obj = {
      'Pieces:': sum.total_pieces,
      'Crt:': parseFloat(sum.total_carat).toFixed(2),
      'Disc% :': parseFloat(sum.final_discount).toFixed(2),
      'Pr/Ct :': parseFloat(sum.final_price).toFixed(2),
      'Amt. :': parseFloat(sum.bid_final_amt).toFixed(2),
      'Off. Disc% :': parseFloat(sum.offerFinalDisc).toFixed(2),
      'Off. Pr/Ct :': parseFloat(sum.offerFinalPr).toFixed(2),
      'Off. Amount :': parseFloat(sum.offerFinalAmt).toFixed(2),
    };
    if (isMobile()) obj = obj;
    return (
      <div className="DiamondDetailPopup">
        {Object.keys(obj).map((x) => {
          return (
            <div className="DiamondDetailPopupItem">
              <span>{x}</span>
              <span>{formatFloat(isMobile() ? obj[x] : obj[x])}</span>
            </div>
          );
        })}
      </div>
    );
  };

  getOfferInput = () => {
    return (
      <div className="offerInputBox">
        <IntlMessages id="app.CompanyName*">
          {(placeholder) => (
            <InputBlock
              value={this.state.company}
              // onChange={e => this.setState({ company: e.target.value })}
              disabled
              label={placeholder}
              placeholder={placeholder}
            />
          )}
        </IntlMessages>
        <IntlMessages id="app.comment">
          {(placeholder) => (
            <InputBlock
              value={this.state.comment}
              onChange={(e) => this.setState({ comment: e.target.value })}
              label={placeholder}
              placeholder={placeholder}
            />
          )}
        </IntlMessages>
      </div>
    );
  };

  checkCheck = () => {
    if (!this.state.checked.length) {
      OpenNotification({
        type: 'error',
        title: 'Please select stone(s) to apply quote.',
      });
      return false;
    } else return true;
  };

  submit = () => {
    if (!this.checkCheck()) return;
    if (!this.state.company.trim()) {
      OpenNotification({ type: 'error', title: 'Please enter company name.' });
      return;
    } else if (this.state.errorMsg) {
      return;
    } else {
      handleSaveQuote(
        this.state.checked,
        this.state.comment,
        this.state.company,
        () => {
          this.props.onClose();
          this.props.clearAll();
        },
        this.props.editOffer ? true : false,
      );
    }
  };

  render() {
    return isMobile() ? (
      <MobileOfferPopup {...this.state} parent={this} />
    ) : (
      <div className="offerWrapper">
        <div className="d-flex align-items-center  offerTopBlock">
          <div className="d-flex align-items-center mr-40">
            <Heading className="popupInnerTitle" title={<IntlMessages id="app.ApplyQuote" />} />
          </div>
          {this.offerHeading()}
        </div>
        <div className="searchPopupCommonTable">
          <div className="searchResultTable tabInnerTableScroll">
            <Table
              {...this.state}
              areAllChecked={true}
              currentType={currentType}
              handleCheck={(e) => this.setState({ checked: e })}
              FilterOption={false}
              canSort={false}
              nodots
              noGrp
            />
          </div>
          <div className="d-flex justify-content-between offerBottomBox flex-wrap">
            {this.getOfferInput()}
            <p className="offerNote mt-20">
              <b>
                <IntlMessages id="app.Note" />:
              </b>{' '}
              <IntlMessages id="app.quoteNote" />
            </p>
          </div>
        </div>
        <div className="sideBarPopupButton">
          <a className="commonButton" onClick={this.submit}>
            <IntlMessages id="app.SaveQuote" />
          </a>
          <a className="commonButton" onClick={this.props.onClose}>
            <IntlMessages id="app.CancelQuote" />
          </a>
          <a
            className="commonButton"
            onClick={() =>
              handleDownloadExcel(
                {},
                this.state.checked.map((x) => x.id),
                () => {},
              )
            }
          >
            <IntlMessages id="app.ExcelExport" />
          </a>
          <a
            className="commonButton"
            onClick={() => {
              if (this.state.checked.length) this.setState({ email: 'stock' });
              else
                OpenNotification({
                  type: 'error',
                  title: 'Please select any stone(s).',
                });
            }}
          >
            <IntlMessages id="app.EmailStock" />
          </a>
          <a
            className="commonButton"
            onClick={() => {
              if (this.state.checked.length) this.setState({ email: 'img' });
              else
                OpenNotification({
                  type: 'error',
                  title: 'Please select any stone(s).',
                });
            }}
          >
            <IntlMessages id="app.EmailPic" />
          </a>
          <SendEmailPopup
            sendEmail={this.state.email}
            onCancel={() => this.setState({ email: '' })}
            ids={this.state.checked.map((x) => x.id)}
            img={this.state.email === 'img'}
            isStock={this.state.email === 'stock'}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ diamondData }) => {
  return { checkedData: diamondData.selectedRows[currentType] || [] };
};

export default connect(mapStateToProps, null)(React.memo(QuotePopup));
