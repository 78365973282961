import React from 'react';
import { useDrag, useDrop } from 'react-dnd';

const cloneDeep = require('lodash/cloneDeep');

const SortingFilterDropdownItem = (props) => {
  const { currentSort } = props;
  const DND_ITEM_TYPE = 'row';
  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);
  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = props.index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // move row
      let r1 = props.sortBy[dragIndex];
      let sortee = cloneDeep(props.sortBy);
      sortee = [...sortee.slice(0, dragIndex), ...sortee.slice(dragIndex + 1, sortee.length)];
      sortee = [...sortee.slice(0, hoverIndex), r1, ...sortee.slice(hoverIndex, sortee.length)];
      props.setSortBy(sortee);
      // move row
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: DND_ITEM_TYPE, index: props.index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  preview(drop(dropRef));
  drag(dragRef);

  return (
    <div ref={dropRef} style={{ opacity }} className="llSortingTitleItem">
      <span ref={dragRef} className="sqvanceImage">
        <img src={require('../../assets/svg/DiamondList/sorting-light.svg')} alt="icon" />
      </span>
      <span className="selectSortingTitle">{props.currentSort.title}</span>
      <div
        className="shortingRoundInner"
        onClick={() => {
          props.toggleSortBy(currentSort.id, !currentSort.desc, true);
        }}
      >
        <img
          src={
            currentSort.desc === false
              ? require('../../assets/svg/DiamondList/sortingup-theme.svg')
              : require('../../assets/svg/DiamondList/sortingup-gray.svg')
          }
          alt="icon"
        />
        <img
          src={
            currentSort.desc
              ? require('../../assets/svg/DiamondList/sortingdown-theme.svg')
              : require('../../assets/svg/DiamondList/sortingdown-gray.svg')
          }
          alt="icon"
        />
      </div>
    </div>
  );
};
export default SortingFilterDropdownItem;
