export const FILTER_MASTER_MAP = {
  cut: 'CUT',
  pol: 'POLISH',
  sym: 'SYMMETRY',
  flu: 'FLUORESCENCE',
  shd: 'SHADE',
  eCln: 'EYECLEAN',
  mlk: 'MILKEY',
  mixTint: 'MIX_TINT',
};

export const COMPOSITE_FILTER_TYPES = {
  BROWN: 'brown',
  EYE_CLN: 'eyeCln',
  MIX_TINT: 'mixTint',
  MLK: 'mlk',
  NO_BGM: 'noBGM',
  THREE_EX: 'threeEX',
  THREE_VG: 'threeVG',
  THREE_VG_PLUS: 'threeVGPlus',
};

export const COMPOSITE_FILTERS = {
  brown: { shd: ['BR1', 'BR2', 'BR3'] },
  eyeCln: { eCln: ['BORDERLINE', 'E1', 'YES'] },
  mixTint: { mixTint: ['MT1', 'MT2', 'MT3'] },
  mlk: { mlk: ['ML0', 'ML1', 'ML2', 'ML3'] },
  noBGM: { mixTint: ['NONE'], mlk: ['NONE'], shd: ['NONE', 'WH', 'YL'] },
  threeEX: { cut: ['EX'], pol: ['EX'], sym: ['EX'] },
  threeVG: { cut: ['VG'], pol: ['EX', 'G', 'VG'], sym: ['EX', 'G', 'VG'] },
  threeVGPlus: { cut: ['EX', 'VG'], pol: ['EX', 'VG'], sym: ['EX', 'VG'] },
};
export const STAGE_KEYS = { SGI: 'sgiSts', SST: 'sSts', WEB: 'webSts', WST: 'wSts' };

// Maintain sequence - sgiSts, sSts, webSts, wSts
export const STAGE_FILTERS = {
  // Available
  AVAILABLE: { or: { sgiSts: undefined, sSts: undefined, webSts: undefined, wSts: 'A' } },
  AVAILABLE_NOT: { or: { sgiSts: undefined, sSts: undefined, webSts: undefined, wSts: 'NA' } },
  // Best Buy
  BEST_BUY: { or: { sgiSts: { nin: ['I'] }, sSts: undefined, webSts: { nin: ['I'] }, wSts: 'D' } },
  BEST_BUY_MEMO: { or: { sgiSts: 'I', sSts: undefined, webSts: 'I', wSts: 'D' } },
  BEST_BUY_MEMO_SGI: { or: { sgiSts: 'I', sSts: undefined, webSts: { nin: ['I'] }, wSts: 'D' } },
  BEST_BUY_ALL: { or: { sgiSts: undefined, sSts: undefined, webSts: undefined, wSts: 'D' } },
  // Hold
  HOLD: { or: { sgiSts: undefined, sSts: { nin: ['Y'] }, webSts: undefined, wSts: 'H' } },
  HOLD_SGI: { or: { sgiSts: undefined, sSts: ['Y'], webSts: undefined, wSts: 'H' } },
  HOLD_ORDER_PENDING: { or: { sgiSts: undefined, sSts: undefined, webSts: undefined, wSts: 'C' } },
  // Memo
  MEMO: { or: { sgiSts: 'I', sSts: undefined, webSts: 'I', wSts: ['M', 'D'] } },
  MEMO_SGI: { or: { sgiSts: 'I', sSts: undefined, webSts: { nin: ['I'] }, wSts: ['M', 'D'] } },
};

// Default options
export const STAGE_OPTIONS_DEFAULT = {
  // Available
  ALL: { label: 'All', color: 'transparent' },
  AVAILABLE: { label: 'Available', color: '#6bc950' },
  AVAILABLE_NOT: { label: 'Not Available', color: '#ff960b' },
  // Best Buy
  BEST_BUY: { label: 'Best Buy', color: '#ffc0cb' },
  BEST_BUY_MEMO: { label: 'Best Buy/On Memo', color: '#ffc0cb' },
  BEST_BUY_MEMO_SGI: { label: 'Best Buy/SGI memo', color: '#ffc0cb' },
  BEST_BUY_ALL: { label: 'Best Buy', color: '#ffc0cb' },
  // Hold
  HOLD: { label: 'On Hold', color: '#db1c1c' },
  HOLD_SGI: { label: 'SGI Hold', color: '#db1c1c' },
  HOLD_ORDER_PENDING: { label: 'Hold/Order Pending', color: '#1c4e7f' },
  // Memo
  MEMO: { label: 'On Memo', color: '#307bea' },
  MEMO_SGI: { label: 'SGI Memo', color: '#307bea' },
};

// Options per page, get from `STAGE_OPTIONS_DEFAULT`
export const STAGE_OPTIONS = {
  DEFAULT: STAGE_OPTIONS_DEFAULT,
  CUSTOMER: {
    ALL: STAGE_OPTIONS_DEFAULT.ALL,
    AVAILABLE: STAGE_OPTIONS_DEFAULT.AVAILABLE,
    HOLD: STAGE_OPTIONS_DEFAULT.HOLD,
    MEMO: STAGE_OPTIONS_DEFAULT.MEMO,
    BEST_BUY_ALL: STAGE_OPTIONS_DEFAULT.BEST_BUY_ALL,
  },
};
