import React, { Component } from 'react';
import Heading from '../Heading';
import TextArea from '../TextArea';
import { HeadingCalc } from './ConfirmStonePopup';
import { ListingCards } from './MobileComfirmStone';
const currentType = 'Note';
class NotePopupMobile extends Component {
  render() {
    return (
      <div className="offerWrapper">
        <div className="d-flex align-items-center justify-space-between offerTopBlock">
          <div className="d-flex align-items-center">
            <Heading className="popupInnerTitle" title="Add Note"></Heading>
          </div>
          {HeadingCalc(this.props.checked)}
        </div>

        <div className="searchInnerResult tabInnerTable">
          <div className="diamondListMobile mobileOfferScroll">
          <ListingCards
              data={this.props.data}
              note={true}
              quoteClick={() => {}}
              currentType={currentType}
              checked={this.props.checked}
              quotePopUp={this.props.parent}
              quoteClick={(e, dt) => this.setState({ quote: e, quoteSelect: dt })}
            />
            <div className="offerInputBox width-100">
              <TextArea
                className="width-100"
                label="Note"
                placeholder="Note"
                value={this.props.data[0] ? this.props.data[0].note : ''}
                onChange={(e) => this.props.parent.ipValChange(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="sideBarPopupButton">
          <a className="commonButton" onClick={this.props.parent.submit}>
            Add Note
          </a>
          <a className="commonOutline" onClick={this.props.onClose}>
            Cancel
          </a>
        </div>
      </div>
    );
  }
}

export default React.memo(NotePopupMobile);
