import React, { useMemo, useState } from 'react';
import { find, findIndex, isInteger, isNumber, map, sum } from 'lodash';
import { isNumeric, parseDecimal } from '../../../util/utils';

const QuoteValueCalcMobile = ({ original, ...props }) => {
  const originalValue = React.useMemo(() => {
    return Number(parseFloat(original.value).toFixed(2));
  }, [original.value]);

  const updatedValue = React.useMemo(() => {
    return original.calcAmount;
  }, [original.calcAmount]);

  const [value, setValue] = React.useState(
    isNumber(updatedValue)
      ? parseFloat(updatedValue).toFixed(2)
      : isNumber(originalValue)
      ? parseFloat(originalValue).toFixed(2)
      : '0.00',
  );

  React.useEffect(() => {
    setValue(parseFloat(updatedValue).toFixed(2));
  }, [updatedValue]);

  const handleChange = React.useCallback((e) => {
    e.preventDefault();
    const _value = e.target.value;
    if (isNumeric(_value)) setValue(_value);
  }, []);

  const handleBlur = React.useCallback((e) => {
    e.preventDefault();
    const _value = e.target.value;
    isNumeric(_value, true) ? props.handleValueBlur(_value, original) : props.handleValueBlur(0, original);
  }, []);

  return (
    <div className="tableInput">
      <input value={value} onChange={handleChange} onBlur={handleBlur} />
    </div>
  );
};

export default QuoteValueCalcMobile;
