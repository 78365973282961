import React from 'react';
import CommonModal from '../CommonModal';
import TextArea from '../TextArea';
import IntlMessages from '../../../util/IntlMessages';
import { handleInsertTrack } from '../../DiamondList/DiamondListFunctions';
import { trackTypeObj } from '../../../constants/Common';

const EnquiryPopup = ({ ...props }) => {
  const [comment, _setRemark] = React.useState('');
  const [error, setError] = React.useState(false);

  const setRemark = React.useCallback((e) => {
    _setRemark(e.target.value);
  }, []);

  React.useEffect(() => {
    setError(false);
    _setRemark('');
  }, [props.visible]);

  const onSubmit = React.useCallback(() => {
    if (!comment.length) return setError(true);

    handleInsertTrack(
      trackTypeObj.ENQUIRY,
      props.checked,
      (status) => {
        // props.clearAll();
        if (status) props.handleCancel();
      },
      undefined,
      comment,
    );
  }, [comment]);

  return (
    <>
      <CommonModal
        visible={true}
        handleOk={onSubmit}
        handleCancel={props.handleCancel}
        title="Add Enquiry"
        footerShow
        submitTitle={<IntlMessages id="app.PlaceEnquiry" />}
        cancelTitle={<IntlMessages id="app.Cancel" />}
      >
        <div className="searchPopupCommon">
          <div className="searchPopupTwoValue">
            <TextArea
              label="Remark *"
              onChange={setRemark}
              value={comment}
              placeholder="Remark"
              error={error && 'Please insert remark'}
            />
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default EnquiryPopup;
