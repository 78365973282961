import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import { Route, Redirect } from 'react-router-dom';
import { isEmpty } from 'util/utils';

import DiamondDetail from 'components/DiamondDetail';

export const StoneDetailRoute = React.memo((props) => {
  const isLoggedIn = useSelector((store) => !isEmpty(store?.auth?.token));
  const location = useLocation();
  const match = useRouteMatch();
  const pathname = location.pathname;

  const newPathname = React.useMemo(() => {
    if (match.params?.name === 'diamond-details') return pathname.replace('diamond-details', 'diamond-detail');
    if (!isLoggedIn) return pathname.replace('diamond-detail', 'dna');
    return pathname;
  }, [isLoggedIn, match.params?.name, pathname]);

  if (newPathname === pathname) return <DiamondDetail {...props} />;
  return <Route render={(routeProps) => <Redirect to={{ ...routeProps.location, pathname: newPathname }} />} />;
});
StoneDetailRoute.displayName = 'DNARoute';
