import React, { Component } from 'react';
import { DatePicker, Select } from 'antd';
import moment from 'moment';
import IntlMessages from '../../../util/IntlMessages';

const dateFormat = 'DD/MM/YYYY';
const { RangePicker } = DatePicker;
const { Option } = Select;

class FilterTopOption extends Component {
  render() {
    return (
      <div className="filterTopOption">
        {this.props.dateFilter === false ? null : (
          <div className="filterSidebarItem">
            <h2 className="filterSidebarTitle">From-To Date : </h2>
            <RangePicker
              defaultValue={[moment('01/07/2020', dateFormat), moment('10/07/2020', dateFormat)]}
              format={dateFormat}
            />
          </div>
        )}
        {/* <div className="filterSidebarItem">
                    <h2 className="filterSidebarTitle">Filter by Customer Name : </h2>
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Please select"
                        defaultValue={['1', '2']}
                    >
                        <Option value="1">Vishal Virani</Option>
                        <Option value="2">Kalpesh Zalavadiya</Option>
                    </Select>
                </div> */}
        {this.props.updateStatus && (
          <div className="filterSidebarItem">
            <h2 className="filterSidebarTitle">Update Status : </h2>
            <Select
              className="updateStausBlock sm-size"
              // mode="multiple"
              style={{ width: '100%' }}
              placeholder="Please select"
              defaultValue="1"
              // onChange={handleChange}
            >
              <Option value="1">New</Option>
              <Option value="2">Open</Option>
              <Option value="3">Won</Option>
              <Option value="4">Close</Option>
            </Select>
          </div>
        )}
        {this.props.SearchReultOption && (
          <div className="filterSidebarItem">
            <h2 className="filterSidebarTitle">
              <IntlMessages id="app.SelectGrouping" />
            </h2>
            <Select
              className="updateStausBlock md-size"
              // mode="multiple"
              style={{ width: '100%' }}
              placeholder="Please select"
              defaultValue="1"
              // onChange={handleChange}
            >
              <Option value="1">
                <IntlMessages id="app.DateWiseGrouping" />
              </Option>
              <Option value="2">
                <IntlMessages id="app.DiamondWiseGrouping" />
              </Option>
            </Select>
          </div>
        )}
      </div>
    );
  }
}
export default FilterTopOption;
