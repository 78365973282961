import React from 'react';
import moment from 'moment';
import { debounce } from 'lodash';
import { isEmpty } from 'util/utils';

class Countdown extends React.Component {
  state = { time: undefined };
  timer;

  componentDidMount() {
    this.setTimer();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currTime !== this.props.currTime) this.setTimer();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  setTimer = (tm) => {
    let time = tm ?? this.props.currTime;
    clearInterval(this.timer);
    this.timer = setInterval(async () => {
      if (isEmpty(this.props.date)) return;
      if (time && moment(time)._isValid) {
        time = moment(time).add(1, 'seconds');
        this.setState({ time: moment(time) });
      }
    }, 1000);
  };

  leadingZero = (num) => {
    return num <= 0 ? '00' : num < 10 && num > 0 ? '0' + num : num;
  };

  getses = (num, label) => {
    return num === 1 || num === '01' ? label : label + 's';
  };

  debouncedGetEndTime = debounce(() => {
    void this.props.bidEndTime?.();
  }, 1000);

  render() {
    if (isEmpty(this.state.time) || !this?.props?.date?.diff) return null;

    const current = this.state.time;
    const diff = moment.duration(this.props.date.diff(current))._data;

    if (diff.days) diff.hours += diff.days * 24;
    if (diff.hours + diff.minutes + diff.seconds === 0) this.debouncedGetEndTime();

    return (
      <div className="App">
        <div className="countDownBlock">
          <div className="countdownItem">
            <span>{this.leadingZero(diff.hours)}</span>
            <span>{this.getses(diff.hours, 'hour')}</span>
          </div>
          <div className="countdownItem">
            <span>{this.leadingZero(diff.minutes)}</span>
            <span>{this.getses(diff.minutes, 'minute')}</span>
          </div>
          <div className="countdownItem">
            <span>{this.leadingZero(diff.seconds)} </span>
            <span>{this.getses(diff.seconds, 'second')}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default Countdown;
