import React from 'react';
import FilterTop from './FilterTop';
import FilterTopOption from './FilterOption';
import { isMobile } from '../../DiamondList/DiamondListFunctions';
import { Dropdown } from 'antd';
import Countdown from '../../Offer/Timer';
import moment from 'moment';
import { LOCAL_STORAGE_VAR } from '../../../constants/Common';
import isEmpty from 'lodash/isEmpty';

const ListingTop = (props) => {
  let quoteDayTimer = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-quoteDay`));
  let timeOfQuote = !isEmpty(quoteDayTimer) ? quoteDayTimer.to : '';
  const gridIcon = () => {
    return (
      <>
        {props.listGridIcon && (
          <div className="gridListIcon">
            <img src={props.image} alt="gridIcon" onClick={props.onClick} />
          </div>
        )}
      </>
    );
  };

  return isMobile() ? (
    <div className="d-flex align-items-center mobileRightAction">
      {gridIcon()}
      {/* {getPath() === LISTINGPAGES.LIST && (
                <div className='filterOption' onClick={this.filter}>
                    <span className='filterStatus'>3</span>
                    <img src={require('../../assets/svg/DiamondList/filter.svg')} alt='filter' />
                </div>
            )} */}
      {props.Cart && (
        <Dropdown
          overlayClassName="mobileSortingDropdown"
          overlay={
            <div className="mobileSortingDropdownList">
              <ul>
                <li>Diamond Wise Grouping</li>
                <li>Date Wise Grouping</li>
              </ul>
            </div>
          }
          trigger={['click']}
        >
          <div className="filterOption mobileSortingIcon" onClick={(e) => e.preventDefault()}>
            <img src={require('../../../assets/svg/DiamondList/mobilesorting.svg')} alt="filter" />
          </div>
        </Dropdown>
      )}
    </div>
  ) : (
    <div className="d-flex j-space-between filterTopMainBlock align-end">
      {props.filterTop && (
        <div className="searchInnerFilterMain">
          <>
            <FilterTop filterTitle="1 Filter" image={require('../../../assets/svg/DiamondList/eye-close.svg')} />
            <FilterTop filterTitle="2 Filter" image={require('../../../assets/svg/DiamondList/eye.svg')} />
            <FilterTop filterTitle="3 Filter" image={require('../../../assets/svg/DiamondList/eye.svg')} />
          </>
        </div>
      )}
      {props.matchpairfilterTop && (
        <div className="searchInnerFilterMain">
          <>
            <FilterTop
              filterTitle="ROUND 0.50-0.69 DEF IFVVS1"
              image={require('../../../assets/svg/DiamondList/eye-close.svg')}
            />
            <FilterTop
              filterTitle="Pear 0.50-0.69 GHI IFVVS1"
              image={require('../../../assets/svg/DiamondList/eye.svg')}
            />
            <FilterTop
              filterTitle="ROUND 0.50-0.69 EF IFVVS2"
              image={require('../../../assets/svg/DiamondList/eye.svg')}
            />
          </>
        </div>
      )}
      {props.Cart && (
        <div className="d-flex">
          <FilterTopOption SearchReultOption dateFilter={false} />
        </div>
      )}
      {/* {props.modify && (
        <button className="commonButton smallButton mr-10" onClick={props.onModify}>
          <IntlMessages id="app.modify" />
        </button>
      )} */}
      {props.timer && (
        <div className="bidEndBlock d-flex align-items-center">
          <span className="bidEndLabel">Quote Day ends in :</span>
          <Countdown date={moment(timeOfQuote)} />
        </div>
      )}
      {gridIcon()}
    </div>
  );
};
export default ListingTop;
